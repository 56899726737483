<template>
  <div class="form">
    <!-- oauth client form component -->
    <div id="oauth-client-form" class="form">
      <h2>Editing Existing OAuth Client</h2>
      <div v-if="error" class="err-msg">{{ error }}</div>
      <div v-if="loading">Loading...</div>
      <div v-else class="form-content">
        <div class="form-input">
          <label for="oauth-client-id"> OAuth Client ID: </label>
          <input
            id="oauth-client-id"
            v-model="oauthClient.client_id"
            name="oauth_client_id"
            type="text"
          />
        </div>
        <div class="form-input">
          <label for="oauth-client-secret"> OAuth Client Secret: </label>
          <input
            id="oauth-client-secret"
            v-model="oauthClient.secret"
            name="oauth_client_secret"
            type="text"
          />
        </div>
        <button class="save-btn" @click="save">Save</button>
      </div>
    </div>
    <!-- oauth client form component -->
  </div>
</template>

<script lang="ts">
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue } from "vue-class-component";
import { OauthClient } from "../../types/index";

class EditOauthClient extends Vue {
  oauthClient: OauthClient = {
    client_id: "",
    secret: "",
  };
  loading: boolean = true;
  error: string = "";

  created() {
    this.getOauthClient(Number(this.$route.params.id)).then(() => {
      this.loading = false;
    });
  }

  async getOauthClient(id: number) {
    try {
      const response: AxiosResponse<{ data: OauthClient }> =
        await apiFetch.oauthClient.getOauthClientDetail(id);
      this.oauthClient = response?.data?.data;
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async save() {
    try {
      await this.editOauthClient(Number(this.$route.params.id));
      this.$router.push({ path: "/oauth-client" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async editOauthClient(id: number) {
    const newOauthClient: OauthClient = { ...this.oauthClient };
    await apiFetch.oauthClient.updateOauthClientDetail(id, newOauthClient);
  }
}
export default EditOauthClient;
</script>
