
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue } from "vue-class-component";
import { OauthClient } from "../../types/index";

class EditOauthClient extends Vue {
  oauthClient: OauthClient = {
    client_id: "",
    secret: "",
  };
  loading: boolean = true;
  error: string = "";

  created() {
    this.getOauthClient(Number(this.$route.params.id)).then(() => {
      this.loading = false;
    });
  }

  async getOauthClient(id: number) {
    try {
      const response: AxiosResponse<{ data: OauthClient }> =
        await apiFetch.oauthClient.getOauthClientDetail(id);
      this.oauthClient = response?.data?.data;
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async save() {
    try {
      await this.editOauthClient(Number(this.$route.params.id));
      this.$router.push({ path: "/oauth-client" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }

  async editOauthClient(id: number) {
    const newOauthClient: OauthClient = { ...this.oauthClient };
    await apiFetch.oauthClient.updateOauthClientDetail(id, newOauthClient);
  }
}
export default EditOauthClient;
